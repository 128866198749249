import { getToken } from '../../helpers/token';
import axios from '../../config/backoffice';

export const fetchTrackerReport = async ({ isDownload, selectedDate, source}, { rejectWithValue }) => {
  const token = getToken();
  try {
    const result = await axios.get(
      `reports/${isDownload ? `tracker-download/${isDownload.group}` : 'tracker'}?` +
        new URLSearchParams({
          date_s: selectedDate.startDate + " 00:00:00",
        }) +
        '&' +
        new URLSearchParams({
          date_e: selectedDate.endDate + " 23:59:59",
        }) +
        (source
          ? '&' +
            new URLSearchParams({
              source: source,
            })
          : ''),
      {
        responseType: isDownload && 'blob',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (isDownload) {
      const filename = `tracker-report=${isDownload.group}.xlsx`;

      // Create download link
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } else {
      return await result.data;
    }
  } catch (error) {
    if (error.response) {
      const { status, message } = error.response.data;
      return rejectWithValue({ status, message });
    } else if (error.request) {
      throw error.request;
    } else {
      console.log('Error', error.message);
    }
  }
};
