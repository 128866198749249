import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import trackerFiltersSelector from '../../state/tracker-report/selectors/filter/filters';
import { downloadTrackerReport, setTrackerReport } from '../../state/tracker-report/async-actions';
import { selectSource, setDateRange } from '../../state/tracker-report/actions';
import DatePicker from './DatePicker';
import DownloadButton from '../ui/buttons/download';

const FiltersRow = ({ type }) => {
  const dispatch = useDispatch();
  const { selectedDate, source } = useSelector(trackerFiltersSelector);
  const [value, setValue] = useState(undefined);

  const handleDownloadSource = () => {
    var isDownload = {
      group: "27"
    };
    dispatch(
      downloadTrackerReport({
        isDownload,
        selectedDate,
        source,
      })
    );
  };

  const handleDownloadOffer = () => {
    var isDownload = {
      group: "3"
    };
    dispatch(
      downloadTrackerReport({
        isDownload,
        selectedDate,
        source,
      })
    );
  };

  const handleDateRange = (newRange) => {
    dispatch(setDateRange(newRange));
  };

  const updateSearchValue = useCallback(
    debounce((str) => {
      dispatch(selectSource(str));
    }, 1000),
    []
  );

  const onChangeInput = (e) => {
    setValue(e.target.value.trim());
    updateSearchValue(e.target.value.trim());
  };

  return (
    <div className='flex justify-between gap-3 items-center pb-5 max-md:flex-col'>
      <div className='flex items-center gap-3 max-md:flex-wrap'>
        <DatePicker handleSetDate={handleDateRange} selectedDate={selectedDate} />
        <div>
          <input
            className='w-full p-3 h-10 text-sm bg-white dark:bg-black-1 focus:bg-transparent rounded-md font-onest-regular border border-grey-2 placeholder-grey-2 dark:placeholder-grey-1 hover:border-black-3 focus:border-black-3 dark:hover:border-grey-4 dark:focus:border-grey-4 read-only:bg-grey-4 dark:read-only:border-grey-0 dark:read-only:bg-black-3 dark:text-grey-3 disabled:bg-grey-3 disabled:text-grey-2 disabled:border-none dark:disabled:border-none dark:disabled:bg-grey-1 dark:disabled:text-black-3 focus:outline-0 focus:ring-0'
            type='text'
            value={value === undefined ? source : value}
            placeholder='Source'
            onChange={onChangeInput}
          />
        </div>
      </div>
      <div className='flex items-center gap-5 max-md:mr-auto'>
        <DownloadButton handleClick={handleDownloadSource}>Download Source Report</DownloadButton>
        {/* <DownloadButton handleClick={handleDownloadOffer}>Download Offer Report</DownloadButton> */}
      </div>
    </div>
  );
};

export default FiltersRow;
