export const COUNTRY = [
  {
    value: 'US',
    name: 'United States',
  },
  {
    value: 'CA',
    name: 'Canada',
  },
  {
    value: 'GB',
    name: 'United Kingdom',
  },
  {
    value: 'AU',
    name: 'Australia',
  },
  {
    value: 'NZ',
    name: 'New Zealand',
  },
  {
    value: 'ZA',
    name: 'South Africa',
  },
  {
    value: 'FR',
    name: 'France',
  },
  {
    value: 'DE',
    name: 'Germany',
  },
  {
    value: 'ES',
    name: 'Spain',
  },
  {
    value: 'IT',
    name: 'Italy',
  },
  {
    value: 'PL',
    name: 'Poland',
  },
  {
    value: 'PT',
    name: 'Portugal',
  },
  {
    value: 'CZ',
    name: 'Czech Republic',
  },
  {
    value: 'IE',
    name: 'Ireland',
  },
  {
    value: 'AT',
    name: 'Austria',
  },
  {
    value: 'CH',
    name: 'Switzerland',
  },
  {
    value: 'BE',
    name: 'Belgium',
  },
  {
    value: 'NL',
    name: 'Netherlands',
  },
];

export const VALUE_TYPES = [
  {
    value: 'leads',
    name: 'Leads',
  },
  {
    value: 'submit',
    name: 'Submit',
  },
  {
    value: 'revenue',
    name: 'Revenue',
  },
];
